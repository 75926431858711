import {useEffect} from 'react'
import {initializeCapabilities, PageView} from 'trello-shared-resources/dist'
import {storeUnreadCommentNotification} from '../modules/Persistence'

/**
 *  The ConnectorView is used to tell Trello what capabilities your
 *  app should have. Use this Connector to add new menu items, buttons
 *  and other similar capabilities.
 *
 */
const Connector = () => {

    let collectUnreadCommentLaunched = false

    useEffect(() => {
        PageView('PowerUp Connector')
    }, [])

    const capabilities = {
        'card-back-section': (t: any) => {
            return {
                title: 'Threaded Comments',
                icon: './icons/Orah_Threaded-commentsGLYPH_White.png',
                content: {
                    type: 'iframe',
                    url: t.signUrl('./index.html?apptype=threadedcomments'),
                    height: 380
                }
            }
        },
        'board-buttons': function (trelloContext: any) {
            try{
                collectUnreadCommentNotifications(trelloContext)
            } catch (error) {
                if(error?.message && !error.message.includes('Missing context') && !error.message.includes('Plugin disabled') &&
                    !error.message.includes('Invalid context, missing board'))
                    throw error
            }
            return {}
        },
        'card-badges': function (trelloContext: any, opts: any) {
            return [{
                dynamic: async () => {
                    try {
                        const unreadCommentNotifications = await trelloContext.get(opts.context.card, 'private', 'threaded_comments_notifications')
                        if (unreadCommentNotifications > 0) {
                            return {
                                text: `${unreadCommentNotifications} new comment${unreadCommentNotifications > 1 ? 's' : ''}`,
                                icon: './icons/24.svg',
                                color: 'blue',
                                refresh: 30
                            }
                        } else {
                            return {refresh: 30}
                        }
                    } catch (error) {
                        if(error?.message && (error.message.includes('Missing context') || error.message.includes('Plugin disabled') ||
                            error.message.includes('Invalid context, missing board'))) return {}
                        else throw error
                    }
                }
            }]
        }
    }

    /**
     * Call every 10 seconds to get the unread comment notifications and store them on each card
     * @param trelloContext The context that we will use to retrieve data.
     */
    const collectUnreadCommentNotifications = async (trelloContext: any) => {
        if(!collectUnreadCommentLaunched) {
            collectUnreadCommentLaunched = true
            storeUnreadCommentNotification(trelloContext)
            setInterval(() => storeUnreadCommentNotification(trelloContext), 10000)
        }
    }
    initializeCapabilities(capabilities)
    return null
}

export default Connector